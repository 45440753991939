.FooterBar {
  padding: 24px;
  margin-top: 70px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid rgb(229, 229, 229);
  z-index: 10000;
  background-color: white;
}